import React from 'react';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import { useThemeObserver } from '@atlaskit/tokens';

import backgroundDark from './assets/background-dark.svg';
import backgroundLight from './assets/background-light.svg';
import { FreeToStandardFeatureComparisonTable } from './free-to-standard-feature-comparison-table';
import { FloatingRecommendation } from './free-to-standard-feature-comparison-table/floating-recommendation';

/**
 * This is the right side panel of the modal.
 */
export const FeatureComparisonPanel = () => {
	const { colorMode } = useThemeObserver();

	return (
		<Flex xcss={outerStyles}>
			<Box xcss={[panelStylesBase, colorMode === 'dark' ? panelStylesDark : panelStylesLight]}>
				<Box xcss={floatingRecommendationStyles}>
					<FloatingRecommendation />
				</Box>
				<FreeToStandardFeatureComparisonTable />
			</Box>
		</Flex>
	);
};

const outerStyles = xcss({
	overflow: 'hidden',
});

const floatingRecommendationStyles = xcss({
	position: 'absolute',
	top: 'space.400',
	left: 'space.800',
});

const panelStylesBase = xcss({
	width: '484px',
	height: '536px',
	position: 'absolute',
});

const panelStylesLight = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: `url(${backgroundLight}) no-repeat`,
});

const panelStylesDark = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: `url(${backgroundDark}) no-repeat`,
});
