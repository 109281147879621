import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	startTrial: {
		id: 'free-to-standard-feature-gate-upgrade-modal.start-trial',
		defaultMessage: 'Start trial',
		description: 'CTA on primary button to start a trial',
	},
	dismiss: {
		id: 'free-to-standard-feature-gate-upgrade-modal.dismiss',
		defaultMessage: 'Maybe later',
		description: 'CTA on secondary button to dismiss the modal',
	},
});
