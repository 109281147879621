import React from 'react';

import { useIntl } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import { Box, Flex, Stack, Text, xcss } from '@atlaskit/primitives';

import gesturalLineDark from './assets/gestural-line-dark.svg';
import gesturalLine from './assets/gestural-line.svg';
import { messages } from './messages';

/**
 * This is the floating recommendation component.
 */
export const FloatingRecommendation = () => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={floatingElementsStyles}>
			<Flex xcss={horizontalFlexStyles}>
				<Stack space="space.050">
					<Heading size="medium">{formatMessage(messages.floatingRecommendationTitle)}</Heading>
					<Flex xcss={subHeadingContainerStyles}>
						<Text maxLines={1} size="medium">
							{formatMessage(messages.floatingRecommendationSubtitle)}
						</Text>
					</Flex>
				</Stack>
			</Flex>
			<Box xcss={gesturalLineStyles}>
				<Image
					src={gesturalLine}
					srcDark={gesturalLineDark}
					alt={formatMessage(messages.gesturalLineAltText)}
				/>
			</Box>
		</Box>
	);
};

const floatingElementsStyles = xcss({
	position: 'absolute',
	zIndex: 'dialog',
	top: 'space.400',
	left: 'space.negative.250',
	width: '397px',
	height: '58.197px',
});

const horizontalFlexStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
});

const subHeadingContainerStyles = xcss({
	width: '150px',
});

const gesturalLineStyles = xcss({
	position: 'absolute',
	zIndex: 'dialog',
	left: '140px',
	top: 'space.100',
	marginLeft: 'space.200',
	marginTop: 'space.200',
});
