import { useQuery } from '@apollo/react-hooks';
import { useContext, useMemo } from 'react';
import OriginTracing from '@atlassiansox/origin-tracing';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { getAGGClient } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import { isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql-error-processor';

import { MpucupStandardFgsUpgradeAndPayDetailsQuery } from '../graphql/MpucupStandardFgsUpgradeAndPayDetailsQuery.agggraphql';

const enum UPGRADE_QUERY {
	HAMS_PRODUCT_KEY = 'confluence.ondemand',
	OFFERING_KEY = '2eda1119-fab4-4699-ab37-ff8f12052cde',
}

export const useCanUpgradeForAllUsersInProduct = (skip?: boolean) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const { cloudId, edition } = useSessionData();

	const isEnrolled = edition === 'FREE' && !skip;

	const { data, error, loading } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		MpucupStandardFgsUpgradeAndPayDetailsQuery,
		{
			client: getAGGClient(),
			variables: {
				cloudId,
				productKey: UPGRADE_QUERY.HAMS_PRODUCT_KEY,
				offeringKey: UPGRADE_QUERY.OFFERING_KEY,
			},
			skip: !isEnrolled,
		},
	);

	if (error && !isErrorMarkedAsHandled(error)) {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'error',
				actionSubject: 'luna-growth-experiment-graphql-error',
				actionSubjectId: 'MpucupStandardFgsUpgradeAndPayDetailsQuery',
				attributes: {
					error: error?.message,
					extraInfo: error?.extraInfo,
					graphqlErrors: error?.graphQLErrors,
					networkErrors: error?.networkError,
					growthFeature: 'confluenceMpucupStandardUpsellFgs',
					isSiteAdmin,
				},
				source: 'confluenceMpucupStandardUpsellFgs',
			},
		}).fire();

		markErrorAsHandled(error);
	}

	const entitlement = data?.tenantContexts?.[0]?.entitlementInfo?.entitlement;
	const canUpgradeAndPayForProductUser = !!(
		entitlement?.transactionAccount.experienceCapabilities?.addPaymentMethodV2?.isAvailableToUser &&
		entitlement?.experienceCapabilities.changeOfferingV2?.isAvailableToUser
	);
	const canUpgradeAndPay = !!(
		isEnrolled &&
		!error &&
		!loading &&
		(isSiteAdmin || canUpgradeAndPayForProductUser)
	);

	const changeOfferingLink = entitlement?.experienceCapabilities?.changeOfferingV2?.experienceUrl;
	const url = useMemo(
		() => buildChangeOfferingLink(changeOfferingLink, !isSiteAdmin),
		[changeOfferingLink, isSiteAdmin],
	);

	return {
		loading,
		error,
		canUpgradeAndPay,
		changeOfferingLink: url,
	};
};

const buildChangeOfferingLink = (url: string | undefined | null, addMpuQueryParam: boolean) => {
	const origin = new OriginTracing({ product: 'confluence' });
	if (!url) {
		return undefined;
	}

	const standardUrl = new URL(url);
	addMpuQueryParam && standardUrl.searchParams.set('enableMpucup', 'true');

	return origin.addToUrl(standardUrl.toString());
};
