import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	standardColumnTitle: {
		id: 'free-to-standard-feature-gate-upgrade-modal.standard-edition-column.title',
		defaultMessage: 'Standard',
		description:
			'Title of the standard edition column in the free to standard feature comparison table.',
	},
});
