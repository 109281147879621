import { token } from '@atlaskit/tokens';

export const GRADIENT_CTA_BUTTON_DEFAULT_START = token('color.chart.purple.bolder');
export const GRADIENT_CTA_BUTTON_DEFAULT_END = token('color.chart.blue.bolder');
export const GRADIENT_CTA_BUTTON_HOVER_START = token('color.chart.purple.bolder.hovered');
export const GRADIENT_CTA_BUTTON_HOVER_END = token('color.chart.blue.bolder.hovered');
export const GRADIENT_CTA_BUTTON_ACTIVE_START = token('color.chart.purple.boldest');
export const GRADIENT_CTA_BUTTON_ACTIVE_END = token('color.chart.blue.boldest');

export const GRADIENT_BACKGROUND_SUBTLEST_START = token('color.background.accent.blue.subtlest');

export const GRADIENT_BACKGROUND_SUBTLEST_END = token('color.background.accent.purple.subtlest');

export const GRADIENT_CTA_BUTTON_INVERTED_BORDER_START = token('color.border.accent.purple');

export const GRADIENT_CTA_BUTTON_INVERTED_BORDER_END = token('color.border.accent.blue');

// (color.background.accent.purple.bolder.pressed, P900) from new color palette
export const GRADIENT_CTA_BUTTON_SELECTED_START = token('color.background.discovery.bold.pressed');

// (color.background.accent.blue.bolder.pressed, B900) from new color palette
export const GRADIENT_CTA_BUTTON_SELECTED_END = token('color.background.selected.bold.pressed');
