import React, { type ReactNode } from 'react';

import { type MessageDescriptor, useIntl } from 'react-intl-next';

import ChartTrendIcon from '@atlaskit/icon/core/chart-trend';
import CommentIcon from '@atlaskit/icon/core/comment';
import LockLoadedIcon from '@atlaskit/icon/core/lock-locked';
import PageIcon from '@atlaskit/icon/core/page';
import PeopleGroupIcon from '@atlaskit/icon/core/people-group';
import PersonAddIcon from '@atlaskit/icon/core/person-add';
import { Flex, Stack, xcss } from '@atlaskit/primitives';

import { FeatureRow } from './feature-row';
import { messages } from './messages';

type Feature = {
	key:
		| 'unlimitedPages'
		| 'unlimitedUsers'
		| 'pagePermissions'
		| 'freeGuestAccess'
		| 'pageInsights'
		| 'atlassianSupport';
	icon: JSX.Element;
	name: MessageDescriptor;
};
const features: Feature[] = [
	{
		key: 'unlimitedPages',
		icon: <PageIcon label="" spacing="spacious" />,
		name: messages.unlimitedPages,
	},
	{
		key: 'unlimitedUsers',
		icon: <PersonAddIcon label="" spacing="spacious" />,
		name: messages.unlimitedUsers,
	},
	{
		key: 'pagePermissions',
		icon: <LockLoadedIcon label="" spacing="spacious" />,
		name: messages.pagePermissions,
	},
	{
		key: 'freeGuestAccess',
		icon: <PeopleGroupIcon label="" spacing="spacious" />,
		name: messages.freeGuestAccess,
	},
	{
		key: 'pageInsights',
		icon: <ChartTrendIcon label="" spacing="spacious" />,
		name: messages.pageInsights,
	},
	{
		key: 'atlassianSupport',
		icon: <CommentIcon label="" spacing="spacious" />,
		name: messages.atlassianSupport,
	},
];

const getFeatureRows = (formatMessage: (descriptor: MessageDescriptor) => string) => {
	const rows: ReactNode[] = [];

	features.forEach(({ key, icon, name }: Feature) =>
		rows.push(<FeatureRow key={key} name={formatMessage(name)} icon={icon} />),
	);

	return rows;
};

export const FeatureNameColumn = () => {
	const { formatMessage } = useIntl();

	return (
		<Flex xcss={featureNameColumnStyles}>
			<Stack space="space.200">{getFeatureRows(formatMessage)}</Stack>
		</Flex>
	);
};

const featureNameColumnStyles = xcss({
	width: '215px',
	height: '293px',
	backgroundColor: 'color.background.input',
	paddingTop: 'space.600',
	paddingInline: 'space.300',
	paddingBottom: 'space.400',
});
