import React, { type ReactNode } from 'react';

import Heading from '@atlaskit/heading';
import { useModal } from '@atlaskit/modal-dialog';

type ModalTitleProps = {
	/**
	 * Children of modal dialog header.
	 */
	children?: ReactNode;

	/**
	 * A `testId` prop is provided for specified elements,
	 * which is a unique string that appears as a data attribute `data-testid` in the rendered code,
	 * serving as a hook for automated tests.
	 */
	testId?: string;
};

export const ModalTitle = (props: ModalTitleProps) => {
	const { children, testId: userDefinedTestId } = props;
	const { titleId, testId: modalTestId } = useModal();

	const testId = userDefinedTestId || (modalTestId && `${modalTestId}--title`);

	return (
		<Heading testId={testId} size="large" id={titleId}>
			{children}
		</Heading>
	);
};
