import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { expValEquals } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';
import { useCanUpgradeForAllUsersInProduct } from '@confluence/experiment-mpucup-free-to-standard-feature-gates';
import { isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';

type ConfluenceMpucupStandardFgsExperimentResponse = {
	isEnrolled: boolean;
	showUpsell?: boolean;
	upgradeUrl?: string;
};

export const useConfluenceMpucupStandardFgsExperiment = (
	skip?: boolean,
): ConfluenceMpucupStandardFgsExperimentResponse => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const abEnabled = Boolean(fg('conf-standard-fgs-for-mpucup-ab-gate'));

	const skipHook = skip || !abEnabled;
	const { error, canUpgradeAndPay, changeOfferingLink } =
		useCanUpgradeForAllUsersInProduct(skipHook);

	if (error) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'error',
				actionSubject: 'luna-growth-experiment-graphql-error',
				actionSubjectId: 'useConfluenceMpucupStandardFgsExperimentAB',
				attributes: {
					error: error?.message,
					extraInfo: error?.extraInfo,
					graphqlErrors: error?.graphQLErrors,
					networkErrors: error?.networkError,
					growthFeature: 'confluenceMpucupStandardUpsellFgs',
					experimentType: 'ab',
				},
				source: 'confluenceMpucupStandardUpsellFgs',
			},
		}).fire();

		if (!isErrorMarkedAsHandled(error)) {
			markErrorAsHandled(error);
		}
	}

	if (!skipHook && canUpgradeAndPay) {
		return {
			isEnrolled:
				abEnabled &&
				(expValEquals('conf-standard-fgs-for-mpucup-ab', 'cohort', 'control') ||
					expValEquals('conf-standard-fgs-for-mpucup-ab', 'cohort', 'variation')),
			upgradeUrl:
				abEnabled && expValEquals('conf-standard-fgs-for-mpucup-ab', 'cohort', 'variation')
					? changeOfferingLink
					: undefined,
		};
	}

	return { isEnrolled: false };
};

export const useConfluenceMpucupStandardFgsExperimentAB = (
	skip?: boolean,
): ConfluenceMpucupStandardFgsExperimentResponse => {
	return useConfluenceMpucupStandardFgsExperiment(skip);
};
