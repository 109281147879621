import gql from 'graphql-tag';

export const MpucupStandardFgsUpgradeAndPayDetailsQuery = gql`
	query MpucupStandardFgsUpgradeAndPayDetailsQuery(
		$cloudId: ID!
		$offeringKey: ID
		$productKey: String!
	) {
		tenantContexts(cloudIds: [$cloudId])
			@optIn(to: ["CcpAllUserUpgradeAndPay", "HamsAllUserUpgradeAndPay"]) {
			entitlementInfo(hamsProductKey: $productKey) {
				entitlement {
					experienceCapabilities {
						changeOfferingV2(offeringKey: $offeringKey) {
							experienceUrl
							isAvailableToUser
						}
					}
					transactionAccount {
						experienceCapabilities {
							addPaymentMethodV2 {
								isAvailableToUser
							}
						}
					}
				}
			}
		}
	}
`;
