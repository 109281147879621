import React from 'react';

import { useIntl } from 'react-intl-next';

import CheckCircleIcon from '@atlaskit/icon/core/check-circle';
import MinusIcon from '@atlaskit/icon/core/minus';
import { Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { messages } from './messages';

export const FreeEditionColumn = () => {
	const { formatMessage } = useIntl();

	return (
		<Stack xcss={freeEditionColumnStyles} alignInline="center" space="space.100">
			<Stack alignInline="center">
				<Text weight="semibold" color="color.text.subtlest">
					{formatMessage(messages.freeColumnTitle)}
				</Text>
			</Stack>
			<Stack space="space.200" xcss={checkIconContainerStyles}>
				<CheckCircleIcon spacing="spacious" label="" color={token('color.icon.disabled')} />
				<MinusIcon spacing="spacious" label="" color="currentColor" />
				<MinusIcon spacing="spacious" label="" color="currentColor" />
				<MinusIcon spacing="spacious" label="" color="currentColor" />
				<MinusIcon spacing="spacious" label="" color="currentColor" />
				<MinusIcon spacing="spacious" label="" color="currentColor" />
			</Stack>
		</Stack>
	);
};

const freeEditionColumnStyles = xcss({
	width: '92px',
	height: '293px',
	backgroundColor: 'color.background.input',
	paddingTop: 'space.150',
});

const checkIconContainerStyles = xcss({
	paddingTop: 'space.075',
	color: 'color.text.disabled',
});
