import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	unlimitedPages: {
		id: 'free-to-standard-feature-gate-upgrade-modal.feature-name-column.unlimited-pages',
		defaultMessage: 'Unlimited pages',
		description: 'The name of a feature available in a paid edition of Confluence.',
	},
	unlimitedUsers: {
		id: 'free-to-standard-feature-gate-upgrade-modal.feature-name-column.unlimited-users',
		defaultMessage: 'Unlimited users',
		description: 'The name of a feature available in a paid edition of Confluence.',
	},
	pagePermissions: {
		id: 'free-to-standard-feature-gate-upgrade-modal.feature-name-column.page-permissions',
		defaultMessage: 'Page permissions',
		description: 'The name of a feature available in a paid edition of Confluence.',
	},
	freeGuestAccess: {
		id: 'free-to-standard-feature-gate-upgrade-modal.feature-name-column.free-guest-access',
		defaultMessage: 'Free guest access',
		description: 'The name of a feature available in a paid edition of Confluence.',
	},
	pageInsights: {
		id: 'free-to-standard-feature-gate-upgrade-modal.feature-name-column.page-insights',
		defaultMessage: 'Page insights',
		description: 'The name of a feature available in a paid edition of Confluence.',
	},
	atlassianSupport: {
		id: 'free-to-standard-feature-gate-upgrade-modal.feature-name-column.atlassian-support',
		defaultMessage: 'Atlassian support',
		description: 'The name of a feature available in a paid edition of Confluence.',
	},
});
