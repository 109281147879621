import React, { type ReactNode } from 'react';

import { useModal } from '@atlaskit/modal-dialog';
import { Inline, xcss } from '@atlaskit/primitives';

const wrapStyles = xcss({
	flexWrap: 'wrap-reverse',
});

export interface ModalFooterProps {
	/**
	 * Children of modal dialog footer.
	 */
	children?: ReactNode;

	/**
	 * A `testId` prop is provided for specified elements,
	 * which is a unique string that appears as a data attribute `data-testid` in the rendered code,
	 * serving as a hook for automated tests.
	 */
	testId?: string;
}

export const ModalFooter = (props: ModalFooterProps) => {
	const { children, testId: userDefinedTestId } = props;
	const { testId: modalTestId } = useModal();

	const testId = userDefinedTestId || (modalTestId && `${modalTestId}--footer`);

	return (
		<Inline alignInline="end" testId={testId} space="space.150" xcss={wrapStyles}>
			{children}
		</Inline>
	);
};
