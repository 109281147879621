import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

const i18n = defineMessages({
	viewAndEditControlsFeatureModalTitle: {
		id: 'experiment-mpucup-free-to-standard-feature-gates.view-and-edit-controls-feature.modal-title',
		defaultMessage: 'Your pages, your rules — let’s go!',
		description:
			'Title for the upgrade modal triggered from the share and restrict dialog upsell and the individual space permissions feature gate',
	},
	permissionControlFeatureModalTitle: {
		id: 'experiment-mpucup-free-to-standard-feature-gates.global-permissions-feature.modal-title',
		defaultMessage: 'Take the next step to take control',
		description:
			'Title for the upgrade modal triggered from the admin, global and space permissions feature gate',
	},
	publicLinksExportFeatureModalTitle: {
		id: 'experiment-mpucup-free-to-standard-feature-gates.public-links-export-feature.modal-title',
		defaultMessage: 'Ready to go public? Start your trial',
		description:
			'Title for the upgrade modal triggered from the admin, space permissions feature gate',
	},
	generalFeatureModalTitle: {
		id: 'experiment-mpucup-free-to-standard-feature-gates.general-feature.modal-tile',
		defaultMessage: 'Your trial starts here — let’s go!',
		description: 'Title for the upgrade modal opened from the feature gates in Free',
	},
});

export const getModalTitle = (feature: string) => {
	switch (feature) {
		case 'shareAndRestrictDialogUpsell':
			return <FormattedMessage {...i18n.viewAndEditControlsFeatureModalTitle} />;
		case 'individualSpacePermission':
			return <FormattedMessage {...i18n.viewAndEditControlsFeatureModalTitle} />;
		case 'publicLinksExportUpsell':
			return <FormattedMessage {...i18n.publicLinksExportFeatureModalTitle} />;
		case 'adminGlobalPermissions':
			return <FormattedMessage {...i18n.permissionControlFeatureModalTitle} />;
		case 'adminSpacePermissions':
			return <FormattedMessage {...i18n.permissionControlFeatureModalTitle} />;
		default:
			return <FormattedMessage {...i18n.generalFeatureModalTitle} />;
	}
};
