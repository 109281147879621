import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import PremiumIcon from '@atlaskit/icon/core/premium';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkWrapper = styled.a({
	display: 'inline-flex',
	alignItems: 'center',
	cursor: 'pointer',
	background: `linear-gradient( 80deg, ${token('color.chart.purple.bolder')}, ${token('color.chart.blue.bolder')} )`,
	font: token('font.body'),
	fontWeight: token('font.weight.bold'),
	width: 'fit-content',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	WebkitBackgroundClip: 'text !important',
	WebkitTextFillColor: 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		height: '20px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.chart.purple.bolder')} !important`,
	},
	marginLeft: token('space.050'),
	gap: token('space.025'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& span:first-child': {
		height: '16px',
		width: '16px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		svg: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.chart.purple.bolder.hovered')} !important`,
		},
		background: `linear-gradient( 80deg, ${token('color.chart.purple.bolder.hovered')}, ${token('color.chart.blue.bolder.hovered')} )`,
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
	},
});

type GradientSubtleLinkButtonProps = PropsWithChildren<{
	href?: string;
	onClick?: () => void;
	target?: string;
}>;

export const GradientSubtleLinkButton: FC<GradientSubtleLinkButtonProps> = ({
	href,
	onClick,
	target,
	children,
}: GradientSubtleLinkButtonProps) => {
	return (
		<LinkWrapper href={href} onClick={onClick} target={target} role="link">
			<PremiumIcon label="" />
			{children}
		</LinkWrapper>
	);
};
