import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	freeColumnTitle: {
		id: 'free-to-standard-feature-gate-upgrade-modal.free-edition-column.title',
		defaultMessage: 'Free',
		description:
			'Title of the free edition column in the free to standard feature comparison table.',
	},
});
