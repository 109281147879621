/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { useEffect } from 'react';

import { jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button, { LinkButton } from '@atlaskit/button/new';
import { Inline } from '@atlaskit/primitives';
import { fireScreenAnalytics, fireUIAnalytics } from '@atlassian/analytics-bridge';
import { Modal } from '@atlassian/growth-pattern-library-modal';

import { FeatureComparisonPanel } from './feature-comparison-panel';
import { messages } from './messages';
import { type FreeToStandardFeatureGateUpgradeModalProps } from './types';

export const FreeToStandardFeatureGateUpgradeModal = ({
	children,
	title,
	subtitle,
	changeOfferingUrl,
	onClose,
	label,
	touchpointId,
	analyticsAttributes = {},
}: FreeToStandardFeatureGateUpgradeModalProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		fireScreenAnalytics(
			createAnalyticsEvent({
				action: 'viewed',
				actionSubjectId: 'freeToStandardFeatureGateUpgradeModal',
				attributes: {
					touchpointId,
					...analyticsAttributes,
				},
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnClose = (trigger: 'blur' | 'dismiss') => {
		const event = createAnalyticsEvent({
			action: trigger,
			actionSubject: 'freeToStandardFeatureGateUpgradeModal',
			source: 'freeToStandardFeatureGateUpgradeModal',
			attributes: {
				touchpointId,
				...analyticsAttributes,
			},
			eventType: 'ui',
		});

		fireUIAnalytics(event);

		onClose();
	};

	const handleStartTrialClick = () => {
		const event = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'startTrial',
			source: 'freeToStandardFeatureGateUpgradeModal',
			attributes: {
				touchpointId,
				...analyticsAttributes,
			},
			eventType: 'ui',
		});

		fireUIAnalytics(event);
	};

	return (
		<Modal
			testId="free-to-standard-feature-gate-upgrade-modal"
			onClose={() => handleOnClose('blur')}
			title={title}
			subtitle={subtitle}
			height="536px"
			actions={
				<Inline alignInline="end" space="space.150">
					<Button
						appearance="subtle"
						testId="free-to-standard-feature-gate-upgrade-modal-dismiss-button"
						onClick={() => handleOnClose('dismiss')}
					>
						{formatMessage(messages.dismiss)}
					</Button>
					<LinkButton
						testId="free-to-standard-feature-gate-upgrade-modal-primary-button"
						appearance="primary"
						href={changeOfferingUrl}
						onClick={handleStartTrialClick}
						target="_blank"
					>
						{formatMessage(messages.startTrial)}
					</LinkButton>
				</Inline>
			}
			right={<FeatureComparisonPanel />}
			autoFocus={false}
			shouldScrollInViewport={false}
			shouldCloseOnEscapePress={true}
			shouldCloseOnOverlayClick={true}
			label={label}
		>
			{children}
		</Modal>
	);
};
