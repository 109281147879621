import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	floatingRecommendationTitle: {
		id: 'free-to-standard-feature-gate-upgrade-modal.floating-recommendation.title',
		defaultMessage: 'Recommended for you',
		description: 'Title for the floating recommendation',
	},
	floatingRecommendationSubtitle: {
		id: 'free-to-standard-feature-gate-upgrade-modal.floating-recommendation.subtitle',
		defaultMessage: 'based on similar teams',
		description: 'Subtitle for the floating recommendation',
	},
	gesturalLineAltText: {
		id: 'free-to-standard-feature-gate-upgrade-modal.floating-recommendation.gestural-line-alt-text',
		defaultMessage: 'A gesture pointing towards the Standard edition',
		description:
			'An image of a line that gestures towards the Standard edition showing that Standard is being recommended.',
	},
});
