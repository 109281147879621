import React, { type ReactNode } from 'react';

import { Inline, Text, xcss } from '@atlaskit/primitives';

/**
 * Shows an icon and feature name so as to depict a feature being compared.
 */
export const FeatureRow = ({ icon, name, key }: { icon: ReactNode; name: string; key: string }) => (
	<Inline key={key} xcss={featureLineStyles}>
		{icon}
		<Text key={`wrapper-${key}`} maxLines={1}>
			{name}
		</Text>
	</Inline>
);

const featureLineStyles = xcss({
	height: '24px',
	maxWidth: '165px',
	gap: 'space.100',
	alignItems: 'center',
});
