import React, { useContext, useRef } from 'react';

import { ModalTransition } from '@atlaskit/modal-dialog';

import { FreeToStandardFeatureGateUpgradeModal } from '@atlassian/free-to-standard-feature-gate-upgrade-modal';

import {
	ExperienceSuccess,
	ExperienceTrackerContext,
	MPUCUP_FREE_TO_STANDARD_FG_MODAL_EXPERIENCE,
} from '@confluence/experience-tracker';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';

import { getModalContent } from './getModalContent';
import { getModalTitle } from './getModalTitle';

export type MpucupFreeToStandardFeatureGateModalProps = {
	touchpointId: string;
	feature: string;
	onClose: () => void;
	changeOfferingUrl: string;
	analyticAttributes?: any;
};

const MpucupFreeToStandardFeatureGateModalComponent = ({
	touchpointId,
	feature,
	onClose,
	changeOfferingUrl,
	analyticAttributes = {},
}: MpucupFreeToStandardFeatureGateModalProps) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const hasExperienceTrackerStartedRef = useRef<Boolean>(false);

	if (!hasExperienceTrackerStartedRef.current) {
		experienceTracker.start({
			name: MPUCUP_FREE_TO_STANDARD_FG_MODAL_EXPERIENCE,
			attributes: analyticAttributes,
		});
		hasExperienceTrackerStartedRef.current = true;
	}

	return (
		<>
			<ModalTransition>
				<FreeToStandardFeatureGateUpgradeModal
					title={getModalTitle(feature)}
					touchpointId={touchpointId}
					changeOfferingUrl={changeOfferingUrl}
					onClose={onClose}
					analyticsAttributes={analyticAttributes}
				>
					{getModalContent(feature)}
				</FreeToStandardFeatureGateUpgradeModal>
			</ModalTransition>
			<ExperienceSuccess
				name={MPUCUP_FREE_TO_STANDARD_FG_MODAL_EXPERIENCE}
				attributes={analyticAttributes}
			/>
		</>
	);
};

export const MpucupFreeToStandardFeatureGateModal = withTransparentErrorBoundary({
	attribution: Attribution.GROWTH_LUNA,
})(MpucupFreeToStandardFeatureGateModalComponent);
