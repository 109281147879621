import { FormattedMessage, defineMessages } from 'react-intl-next';
import React from 'react';

import { Anchor, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import LockUnlockedIcon from '@atlaskit/icon/core/lock-unlocked';
import CreditCardIcon from '@atlaskit/icon/core/credit-card';
import CalendarIcon from '@atlaskit/icon/core/calendar';

const linkStyles = xcss({ textDecoration: 'none' });

const bulletContainerStyles = xcss({
	alignItems: 'center',
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
	height: 'space.500',
});

const contentContainerStyles = xcss({
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});

const i18n = defineMessages({
	featureSpecificModalBulletOne: {
		id: 'experiment-mpucup-free-to-standard-feature-gates.feature-specific.modal-bullet-one',
		defaultMessage: 'Get {featureName}, plus more features',
		description: 'First bullet for the upgrade modal opened from the feature gates in Free',
	},
	generalFeatureModalBulletOne: {
		id: 'experiment-mpucup-free-to-standard-feature-gates.general-feature.modal-bullet-one',
		defaultMessage: 'Get 10+ advanced features',
		description: 'First bullet for the upgrade modal opened from the feature gates in Free',
	},
	generalFeatureModalBulletTwo: {
		id: 'experiment-mpucup-free-to-standard-feature-gates.general-feature.modal-bullet-two',
		defaultMessage: 'Try free for 14 days, no credit card required',
		description: 'Second bullet for the upgrade modal opened from the feature gates in Free',
	},
	generalFeatureModalBulletThree: {
		id: 'experiment-mpucup-free-to-standard-feature-gates.general-feature.modal-bullet-three',
		defaultMessage: 'We’ll send a reminder 3 days before the trial ends',
		description: 'Third bullet for the upgrade modal opened from the feature gates in Free',
	},
	legalDisclaimer: {
		id: 'experiment-mpucup-free-to-standard-feature-gates.legal-statement',
		defaultMessage:
			'By clicking below, you agree to the <a>Atlassian Cloud Terms</a> of Service and <link>Privacy Policy.</link>',
		description: 'Legal disclaimer on the upgrade modal opened from the feature gates in Free',
	},
});

export const getModalContent = (feature: string) => {
	return (
		<Stack space="space.400" xcss={contentContainerStyles}>
			<Stack space="space.200">
				<Inline space="space.200" xcss={bulletContainerStyles}>
					<LockUnlockedIcon label="" spacing="spacious" />
					<Text>{getModalBulletOne(feature)}</Text>
				</Inline>
				<Inline space="space.200" xcss={bulletContainerStyles}>
					<CreditCardIcon label="" spacing="spacious" />
					<Text>
						<FormattedMessage {...i18n.generalFeatureModalBulletTwo} />
					</Text>
				</Inline>
				<Inline space="space.200" xcss={bulletContainerStyles}>
					<CalendarIcon label="" spacing="spacious" />
					<Text>
						<FormattedMessage {...i18n.generalFeatureModalBulletThree} />
					</Text>
				</Inline>
			</Stack>
			<Text size="small">
				<FormattedMessage
					{...i18n.legalDisclaimer}
					values={{
						a: (chunks: React.ReactNode) => (
							<Anchor
								target="_blank"
								aria-label="Atlassian Customer Agreement"
								href="https://www.atlassian.com/legal/customer-agreement"
								xcss={linkStyles}
							>
								{chunks}
							</Anchor>
						),
						link: (chunks: React.ReactNode) => (
							<Anchor
								target="_blank"
								aria-label="Atlassian Privacy Policy"
								href="https://www.atlassian.com/legal/privacy-policy"
								xcss={linkStyles}
							>
								{chunks}
							</Anchor>
						),
					}}
				/>
			</Text>
		</Stack>
	);
};

const getModalBulletOne = (feature: string) => {
	switch (feature) {
		case 'shareAndRestrictDialogUpsell':
			return (
				<FormattedMessage
					{...i18n.featureSpecificModalBulletOne}
					values={{ featureName: 'view/edit controls' }}
				/>
			);
		case 'individualSpacePermission':
			return (
				<FormattedMessage
					{...i18n.featureSpecificModalBulletOne}
					values={{ featureName: 'view/edit controls' }}
				/>
			);
		case 'publicLinksExportUpsell':
			return (
				<FormattedMessage
					{...i18n.featureSpecificModalBulletOne}
					values={{ featureName: 'public links' }}
				/>
			);
		case 'adminGlobalPermissions':
			return (
				<FormattedMessage
					{...i18n.featureSpecificModalBulletOne}
					values={{ featureName: 'global permissions' }}
				/>
			);
		case 'adminSpacePermissions':
			return (
				<FormattedMessage
					{...i18n.featureSpecificModalBulletOne}
					values={{ featureName: 'space permissions' }}
				/>
			);
		default:
			return <FormattedMessage {...i18n.generalFeatureModalBulletOne} />;
	}
};
