import React from 'react';

import { useIntl } from 'react-intl-next';

import { Flex, xcss } from '@atlaskit/primitives';

import { FeatureNameColumn } from './feature-name-column';
import { FreeEditionColumn } from './free-edition-column';
import { messages } from './messages';
import { StandardEditionColumn } from './standard-edition-column';

/**
 * This component is a table that compares features in the Free and Standard editions.
 */
export const FreeToStandardFeatureComparisonTable = () => {
	const { formatMessage } = useIntl();

	return (
		<div title={formatMessage(messages.altText)}>
			<Flex xcss={tableStyles} alignItems="center" justifyContent="center">
				<FeatureNameColumn />
				<StandardEditionColumn />
				<FreeEditionColumn />
			</Flex>
		</div>
	);
};

const tableStyles = xcss({
	top: '118px',
	position: 'relative',
});
