import { styled } from '@compiled/react';

import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ButtonBase = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		display: 'inline-flex',
		alignItems: 'center',
		padding: `${token('space.075')} ${token('space.150')}`,
		gap: `${token('space.075')}`,
		textDecoration: 'none',
	},
});
