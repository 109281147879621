import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	altText: {
		id: 'free-to-standard-feature-gate-upgrade-modal.feature-plan-comparison-table.alt-text',
		defaultMessage:
			'Plan comparison table between the Standard and Free plan. Free and Standard both include unlimited pages. Standard includes unlimited users, page permissions, free guest access, page insights, and Atlassian support.',
		description: 'Alt text for accessibility for the free to standard feature comparison table',
	},
});
