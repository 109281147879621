import React from 'react';

import { useIntl } from 'react-intl-next';

import CheckCircleIcon from '@atlaskit/icon/core/check-circle';
import { Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { messages } from './messages';

export const StandardEditionColumn = () => {
	const { formatMessage } = useIntl();

	return (
		<Stack xcss={standardEditionColumnStyles} alignInline="center" space="space.200">
			<Stack alignInline="center">
				<Text weight="semibold" color="color.text.accent.blue.bolder">
					{formatMessage(messages.standardColumnTitle)}
				</Text>
			</Stack>
			<Stack space="space.200">
				<CheckCircleIcon spacing="spacious" label="" color={token('color.icon.accent.green')} />
				<CheckCircleIcon spacing="spacious" label="" color={token('color.icon.accent.green')} />
				<CheckCircleIcon spacing="spacious" label="" color={token('color.icon.accent.green')} />
				<CheckCircleIcon spacing="spacious" label="" color={token('color.icon.accent.green')} />
				<CheckCircleIcon spacing="spacious" label="" color={token('color.icon.accent.green')} />
				<CheckCircleIcon spacing="spacious" label="" color={token('color.icon.accent.green')} />
			</Stack>
		</Stack>
	);
};

const standardEditionColumnStyles = xcss({
	width: '90px',
	height: '321px',
	backgroundColor: 'color.background.input',
	borderRadius: '3px',
	borderWidth: '1px',
	paddingTop: 'space.300',
	boxShadow: 'elevation.shadow.overlay',
	zIndex: 'spotlight', // allows the boxShadow to really pop
});
