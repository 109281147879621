import React, { type ReactNode } from 'react';

import { TouchScrollable } from 'react-scrolllock';

import { useModal } from '@atlaskit/modal-dialog';
import { Stack, xcss } from '@atlaskit/primitives';

const container = xcss({
	flex: '1 1 auto',
	overflowY: 'auto',
});

type ModalBodyProps = {
	/**
	 * Children of modal dialog header.
	 */
	children?: ReactNode;

	/**
	 * A `testId` prop is provided for specified elements,
	 * which is a unique string that appears as a data attribute `data-testid` in the rendered code,
	 * serving as a hook for automated tests.
	 */
	testId?: string;
};

export const ModalBody = (props: ModalBodyProps) => {
	const { children, testId: userDefinedTestId } = props;
	const { testId: modalTestId } = useModal();

	const testId = userDefinedTestId || (modalTestId && `${modalTestId}--body`);

	return (
		<TouchScrollable>
			<Stack space="space.300" xcss={container} testId={testId}>
				{children}
			</Stack>
		</TouchScrollable>
	);
};
