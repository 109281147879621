import gql from 'graphql-tag';

/* Note that GraphQL fragments like this must be in a file that does NOT have the extension ".graphql.js"
  because our build auditor will attempt to verify anything in a "graphql.js" as a full query */
export const CcpEntitlementFragment = gql`
	fragment CcpEntitlementFragment on Query {
		tenantContext {
			licenseStates {
				confluence {
					ccpEntitlementId
				}
			}
		}
		tenant {
			cloudId
		}
	}
`;
